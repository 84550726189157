<template>
  <div>
    <base-card>
        <div class="box-grey-dark" v-if="currentStateSlug || currentCitySlug">
            <i class="far fa-location-dot" />
            <div class="d-flex flex-column" v-if="currentCitySlug">
                <span>Cidade</span>
                <p>{{ currentItem.city }}</p>
            </div>
            <div class="d-flex flex-column" v-else>
                <span>Estado</span>
                <p>{{ currentItem.state }}</p>
            </div>
        </div>

        <base-label :text="StringLanguage.setTheRegion" v-if="!currentStateSlug" />
        <base-label :text="StringLanguage.setTheCities" v-else-if="!currentCitySlug" />
        <base-label :text="StringLanguage.setTheDistricts" v-else />

        <place-list-input
            :placeholder="getPlaceholder()"
            search
            :countrySlug="currentCountrySlug"
            :citySlug="currentCitySlug"
            :stateSlug="currentStateSlug"
            @selectedRegion="onSelectRegion"
        />

        <div class="d-flex flex-column mt_15" v-if="!currentStateSlug">
            <div class="state" v-for="(state, index) in getPayloadBySlug()" :key="index">
            <div class="state-box" @click="onSelectState(state.slug)">
                <span class="badge badge-state">E</span>
                <div class="d-flex flex-column">
                <p class="name">{{ state.state }}</p>
                <p class="records">
                    {{ state.cities.length > 0 ? `${state.cities.length} cidades selecionadas` : StringLanguage.noCities }}
                </p>
                </div>
                <i class="fas fa-chevron-right" />
            </div>
            <base-button design="four ml_15" @click="deleteBySlug(state.slug)">
                <i class="far fa-trash-alt" />
            </base-button>
            </div>
        </div>

        <div class="d-flex flex-column mt_15" v-else-if="!currentCitySlug">
            <div class="city" v-for="(city, index) in getPayloadBySlug(currentStateSlug)" :key="index" >
            <div class="city-box" @click="onSelectCity(currentStateSlug, city.slug)">
                <span class="badge badge-city">C</span>
                <div class="d-flex flex-column">
                <p class="name">{{ city.city }}</p>
                <p class="records">
                    {{ city.neighborhoods.length > 0 ? `${city.neighborhoods.length} bairros selecionados` : StringLanguage.noDistricts }}
                </p>
                </div>
                <i class="fas fa-chevron-right" />
            </div>
            <base-button design="four ml_15" @click="deleteBySlug(currentStateSlug, city.slug)">
                <i class="far fa-trash-alt" />
            </base-button>
            </div>
        </div>

        <div class="d-flex flex-column mt_15" v-else>
            <div class="district" v-for="(neighborhood, index) in getPayloadBySlug(currentStateSlug, currentCitySlug)" :key="index">
            <div class="district-box">
                <span class="badge badge-district">B</span>
                <div class="d-flex flex-column">
                <p class="name">{{ neighborhood.neighborhood }}</p>
                </div>
            </div>
            <base-button design="four ml_15" @click="deleteBySlug(currentStateSlug, currentCitySlug, neighborhood.slug)">
                <i class="far fa-trash-alt" />
            </base-button>
            </div>
      </div>
    </base-card>
  </div>
</template>
<script>
import BaseLabel from '@/components/Label';
import BaseButton from '@/components/Button';
import BaseCard from '@/components/Card';
import PlaceListInput from '@/containers/PlaceListInput';

const components = {
  PlaceListInput,
  BaseButton,
  BaseLabel,
  BaseCard,
};

export default {
  components,
  data() {
    return {
      step: 1,
      currentCountrySlug: 'brasil',
      currentStateSlug: null,
      currentCitySlug: null,
      currentItem: null,
      payload: {
        type: 'regionRange',
        value: [],
      }
    };
  },
  methods: {
    prevStep() {
        const { currentStateSlug, currentCitySlug } = this;
        if(currentCitySlug) {
            this.currentCitySlug = null;
            this.onSelectState(currentStateSlug);
            this.step = 2;
        } else if(currentStateSlug) {
            this.currentStateSlug = null;
            this.currentItem = null;
            this.step = 1;
        }
    },
    getPlaceholder() {
        if(this.currentCitySlug) {
            return StringLanguage.searchByDistrict
        } else if(this.currentStateSlug) {
            return StringLanguage.searchByCity
        } else {
            return StringLanguage.searchByState
        }
    },
    onSelectRegion(option) {
        const { payload, currentStateSlug, currentCitySlug } = this;
        
        if(!currentStateSlug) {
            //Está adicionando um estado
            if(!payload.value.find(state => state.slug == option.data)) {
                payload.value.unshift({ state: option.title, slug: option.data, cities: [] });
            }
        } else {
            const stateIndex = payload.value.findIndex(s => s.slug == currentStateSlug);
            if(!currentCitySlug) {
                //Está adicionando uma cidade
                if(!payload.value[stateIndex].cities.find(city => city.slug == option.data)) { 
                    payload.value[stateIndex].cities.unshift({
                        city: option.title,
                        slug: option.data,
                        neighborhoods: []
                    });
                }
            } else { 
                //Está adicionando um bairro
                const cityIndex = payload.value[stateIndex].cities.findIndex(s => s.slug == currentCitySlug);
                if(!payload.value[stateIndex].cities[cityIndex].neighborhoods.find(n => n.slug == option.data)) {
                    payload.value[stateIndex].cities[cityIndex].neighborhoods.unshift({ neighborhood: option.title, slug: option.data })
                }
            }
        }

        this.$emit('save', payload);
    },
    onSelectState(stateSlug) {
        const state = this.payload.value.find(s => s.slug == stateSlug);
        this.currentStateSlug = stateSlug;
        this.currentItem = state;
        this.step = 2;
    },
    onSelectCity(stateSlug, citySlug) {
        const state = this.payload.value.find(s => s.slug == stateSlug);
        this.currentStateSlug = stateSlug;

        const city = state.cities.find(s => s.slug == citySlug);
        this.currentCitySlug = citySlug;
        this.currentItem = city;
        this.step = 3;
    },
    getPayloadBySlug(stateSlug = null, citySlug = null) {
        const states = this.payload.value;
        if(!stateSlug) { //Está buscando os estados selecionados
            return states;
        } else {
            const state = states.find(s => s.slug == stateSlug);
            if(!citySlug) { //Está buscando as cidades selecionadas
                return state ? state.cities : [];
            } else { //Está buscando os bairros selecionados
                const city = state.cities.find(c => c.slug == citySlug);
                return city ? city.neighborhoods : [];
            }
        }
    },
    deleteBySlug(stateSlug = null, citySlug = null, neighborhoodSlug = null) {
        const states = this.payload.value;
        const stateIndex = stateSlug ? states.findIndex(s => s.slug == stateSlug) : -1;
        const cityIndex = stateIndex > -1 && citySlug ? states[stateIndex].cities.findIndex(s => s.slug == citySlug) : -1;
        const neighborhoodIndex = cityIndex > -1 && neighborhoodSlug ? states[stateIndex].cities[cityIndex].neighborhoods.findIndex(s => s.slug == neighborhoodSlug) : -1;
        
        if(neighborhoodIndex > -1) {
            const { neighborhoods } = states[stateIndex].cities[cityIndex];
            this.payload.value[stateIndex].cities[cityIndex].neighborhoods = neighborhoods.filter((item, index) => index != neighborhoodIndex);
        } else if(cityIndex > -1) {
            const { cities } = states[stateIndex];
            this.payload.value[stateIndex].cities = cities.filter((item, index) => index != cityIndex);
        } else if(stateIndex > -1) {
            this.payload.value = states.filter((item, index) => index != stateIndex);
        }
        this.$emit('save', this.payload);
    }
  },
  watch: {
    step: {
      immediate: true,
      handler(newValue) {
        this.$emit('currentStep', newValue);
      },
    },
  },
};
</script>
<style scoped>
.address-box {
  display: flex;
  align-items: stretch;
  margin: 10px 0;
}
.address-box .address {
  background: var(--box-up);
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  color: #aaaaaaed;
}
.address-box .address p {
  font-size: 12px;
  line-height: 15px;
  margin-left: 10px;
  font-weight: 300;
}
.address-box .address i {
  font-size: 15px;
  color: var(--icon-inactive);
}

:is(.state, .city) {
  display: flex;
  align-items: center;
}

.district {
  display: flex;
  align-items: stretch;
}

.state:not(:last-child) {
  margin-bottom: 15px;
}

:is(.city, .district):not(:last-child) {
  margin-bottom: 10px;
}

:is(.state, .city) :is(.state-box, .city-box) {
  background: rgba(46, 52, 56, 0.5);
  border-radius: 10px;
  padding: 15px 20px 15px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.district .district-box {
  background: var(--box-up);
  border-radius: 10px;
  padding: 7px 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

:is(.state, .city, .district) :is(.state-box, .city-box, .district-box) .badge {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-right: 15px;
}

.state .state-box .badge.badge-state {
  background: #264a4a;
  color: #2dffe6;
}

.city .city-box .badge.badge-city {
  background: rgba(130, 217, 151, 0.15);
  color: #82d997;
}

.district .district-box .badge.badge-district {
  background: rgba(45, 154, 255, 0.15);
  color: #2d9aff;
}
:is(.state, .city) :is(.state-box, .city-box) .name {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #cccccc;
  margin-bottom: 5px;
}

.district .name {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #cccccc;
}

:is(.state, .city) :is(.state-box, .city-box) .records {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(204, 204, 204, 0.5);
}

:is(.state, .city) :is(.state-box, .city-box) i.fa-chevron-right {
  color: var(--icon-inactive);
  font-size: 10px;
  position: absolute;
  right: 20px;
}

.fa-trash-alt {
  font-size: 17px;
  color: var(--icon-inactive);
}

.box-grey-dark {
  background: rgba(30, 33, 36, 0.45);
  border-radius: 15px;
  padding: 20px 15px;
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
}

.box-grey-dark i.fa-location-dot {
  color: var(--icon-inactive);
  font-size: 16px;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid rgba(111, 118, 126, 0.35);
  display: flex;
  align-items: center;
}

.box-grey-dark span {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(170, 170, 171, 0.85);
  display: block;
  margin-bottom: 5px;
}

.box-grey-dark p {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  color: var(--label-primary);
}
</style>
