<template>
  <div>
    <base-card v-if="step === 1">
      <base-label :text="StringLanguage.setTheRegion" />
      <address-input
        :placeholder="StringLanguage.searchByState"
        search
        :records="4"
        recordsText="estados"
      />

      <div class="d-flex flex-column mt_15">
        <div class="state" v-for="(state, index) in states" :key="index">
          <div class="state-box" @click="(stateCopy = state), (step = 2)">
            <span class="badge badge-state">E</span>
            <div class="d-flex flex-column">
              <p class="name">{{ state.name }}</p>
              <p class="records">
                {{
                  state.cities.length > 0
                    ? `${state.cities.length} cidades selecionadas`
                    : StringLanguage.noCities
                }}
              </p>
            </div>
            <i class="fas fa-chevron-right" />
          </div>
          <base-button design="four ml_15">
            <i class="far fa-trash-alt" />
          </base-button>
        </div>
      </div>
    </base-card>

    <base-card v-if="step === 2">
      <div class="box-grey-dark">
        <i class="far fa-location-dot" />
        <div class="d-flex flex-column">
          <span>Estado</span>
          <p>{{ stateCopy.name }}</p>
        </div>
      </div>

      <base-label :text="StringLanguage.setTheCities" />
      <address-input
        :placeholder="StringLanguage.searchByCity"
        search
        :records="4"
        recordsText="cidades"
      />

      <div class="d-flex flex-column mt_15">
        <div
          class="city"
          v-for="(city, index) in stateCopy.cities"
          :key="index"
        >
          <div class="city-box" @click="(cityCopy = city), (step = 3)">
            <span class="badge badge-city">C</span>
            <div class="d-flex flex-column">
              <p class="name">{{ city.name }}</p>
              <p class="records">
                {{
                  city.districts.length > 0
                    ? `${city.districts.length} bairros selecionados`
                    : StringLanguage.noDistricts
                }}
              </p>
            </div>
            <i class="fas fa-chevron-right" />
          </div>
          <base-button design="four ml_15">
            <i class="far fa-trash-alt" />
          </base-button>
        </div>
      </div>
    </base-card>

    <base-card v-if="step === 3">
      <div class="box-grey-dark">
        <i class="far fa-location-dot" />
        <div class="d-flex flex-column">
          <span>Cidade</span>
          <p>{{ cityCopy.name }}</p>
        </div>
      </div>

      <base-label :text="StringLanguage.setTheDistricts" />
      <address-input
        :placeholder="StringLanguage.searchByDistrict"
        search
        :records="5"
        recordsText="bairros"
      />

      <div class="d-flex flex-column mt_15">
        <div
          class="district"
          v-for="(district, index) in cityCopy.districts"
          :key="index"
        >
          <div class="district-box">
            <span class="badge badge-district">B</span>
            <div class="d-flex flex-column">
              <p class="name">{{ district }}</p>
            </div>
          </div>
          <base-button design="four ml_15">
            <i class="far fa-trash-alt" />
          </base-button>
        </div>
      </div>
    </base-card>
  </div>
</template>
<script>
import BaseLabel from '@/components/Label';
import BaseButton from '@/components/Button';
import BaseCard from '@/components/Card';

import AddressInput from '@/containers/AddressInput';
import Location from '@/plugins/core/schema/configurations/BasicInfo/Location';

const components = {
  AddressInput,
  BaseButton,
  BaseLabel,
  BaseCard,
};

export default {
  components,
  data() {
    return {
      selectedAddresses: [],
      step: 1,
      payload: {
        type: 'geographicDistrict',
        value: {
          addresses: [],
        },
      },
      states: [
        {
          name: 'Paraíba',
          cities: [
            {
              name: 'João Pessoa',
              districts: [
                'Altiplano',
                'Centro',
                'Ernesto Geisel',
                'Cristo',
                'Valentina',
              ],
            },
            {
              name: 'Patos',
              districts: [],
            },
            {
              name: 'Campina Grande',
              districts: [],
            },
            {
              name: 'Guarabira',
              districts: [],
            },
          ],
        },
        {
          name: 'São Paulo',
          cities: [],
        },
        {
          name: 'Curitiba',
          cities: [],
        },
        {
          name: 'Mato Grosso',
          cities: [],
        },
      ],
      stateCopy: [],
      cityCopy: [],
    };
  },
  methods: {
    prevStep() {
      this.step -= 1;
    },
    onAddressSelected(option) {
      if (!option.address) {
        return;
      }

      const address = Location.fromObject(option.address);
      this.payload.value.addresses.push(address);
      this.$emit('save', this.payload);
    },
    formattedAddress(address) {
      const addressStrings = [
        `${address.address || ''} ${
          address.number ? `, ${address.number}` : ''
        }`,
        `${address.district}, ${address.city}`,
        `${address.state}, ${address.country} ${
          address.zipcode ? `- ${address.zipcode}` : ''
        }`,
      ];

      return addressStrings.join(' - ');
    },
    removeAddress(index) {
      confirm('Deseja mesmo remover o endereço?', (res) => {
        if (res) {
          this.payload.value.addresses.splice(index, 1);
          this.$emit('save', this.payload);
        }
      });
    },
  },
  watch: {
    step: {
      immediate: true,
      handler(newValue) {
        this.$emit('currentStep', newValue);
      },
    },
  },
};
</script>
<style scoped>
.address-box {
  display: flex;
  align-items: stretch;
  margin: 10px 0;
}
.address-box .address {
  background: var(--box-up);
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  color: #aaaaaaed;
}
.address-box .address p {
  font-size: 12px;
  line-height: 15px;
  margin-left: 10px;
  font-weight: 300;
}
.address-box .address i {
  font-size: 15px;
  color: var(--icon-inactive);
}

:is(.state, .city) {
  display: flex;
  align-items: center;
}

.district {
  display: flex;
  align-items: stretch;
}

.state:not(:last-child) {
  margin-bottom: 15px;
}

:is(.city, .district):not(:last-child) {
  margin-bottom: 10px;
}

:is(.state, .city) :is(.state-box, .city-box) {
  background: rgba(46, 52, 56, 0.5);
  border-radius: 10px;
  padding: 15px 20px 15px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.district .district-box {
  background: var(--box-up);
  border-radius: 10px;
  padding: 7px 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

:is(.state, .city, .district) :is(.state-box, .city-box, .district-box) .badge {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-right: 15px;
}

.state .state-box .badge.badge-state {
  background: #264a4a;
  color: #2dffe6;
}

.city .city-box .badge.badge-city {
  background: rgba(130, 217, 151, 0.15);
  color: #82d997;
}

.district .district-box .badge.badge-district {
  background: rgba(45, 154, 255, 0.15);
  color: #2d9aff;
}
:is(.state, .city) :is(.state-box, .city-box) .name {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #cccccc;
  margin-bottom: 5px;
}

.district .name {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #cccccc;
}

:is(.state, .city) :is(.state-box, .city-box) .records {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(204, 204, 204, 0.5);
}

:is(.state, .city) :is(.state-box, .city-box) i.fa-chevron-right {
  color: var(--icon-inactive);
  font-size: 10px;
  position: absolute;
  right: 20px;
}

.fa-trash-alt {
  font-size: 17px;
  color: var(--icon-inactive);
}

.box-grey-dark {
  background: rgba(30, 33, 36, 0.45);
  border-radius: 15px;
  padding: 20px 15px;
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
}

.box-grey-dark i.fa-location-dot {
  color: var(--icon-inactive);
  font-size: 16px;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid rgba(111, 118, 126, 0.35);
  display: flex;
  align-items: center;
}

.box-grey-dark span {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(170, 170, 171, 0.85);
  display: block;
  margin-bottom: 5px;
}

.box-grey-dark p {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  color: var(--label-primary);
}
</style>
