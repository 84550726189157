<template>
  <div class="benefit-infos">
    <div class="totalizer">
      <div class="card">
        <p class="value">
          {{ Utils.convertMoney(benefit.sales_total) }}
        </p>
        <p>Valor em vendas</p>
      </div>
      <div class="card">
        <p class="value">
          {{ (benefit.uses || 0.0).toFixed(0) }}
        </p>
        <p>Quantidade de usos</p>
      </div>
      <div class="card">
        <p class="value">
          {{
            Utils.convertMoney((benefit.sales_total || 0) / (benefit.uses || 1))
          }}
        </p>
        <p>Ticket médio</p>
      </div>
    </div>

    <chart-box
      v-if="!isMobile"
      title="Usos por dia"
      type="bar"
      :chartData="chartData"
      :chartOptions="chartOptions"
      :style="'height: 355px'"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Utils from '@/utils';

import ChartBox from '@/containers/ChartBox';

dayjs.extend(isSameOrBefore);

export default {
  components: {
    ChartBox,
  },
  props: {
    benefit: { type: Object, default: () => ({ reports: [] }) },
  },
  data() {
    return {
      Utils,
      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            data: [],
            backgroundColor: '#82D997',
            borderColor: '#82D997',
            borderRadius: 30,
            borderSkipped: false,
            stack: 'Stack 0',
          },
        ],
      },
      chartOptions: {
        cutoutPercentage: 62.5,
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
          fullWidth: false,
        },
        barThickness: 5,
      },
    };
  },
  created() {
    this.chartData.labels = this.getPeriodRange();
    this.chartData.datasets[0].data = this.getUsesByDay();
  },
  methods: {
    getPeriodRange() {
      const currentDate = dayjs();
      let begin = dayjs().subtract(29, 'day');

      const period = [];

      while (begin.isSameOrBefore(currentDate)) {
        period.push(begin.format('DD/MM'));
        begin = begin.add(1, 'day');
      }

      return period;
    },
    getUsesByDay() {
      const period = this.getPeriodRange();

      const usesByDay = period.reduce((prev, next) => {
        return {
          ...prev,
          [next]: 0,
        };
      }, {});

      this.benefit.reports.forEach((report) => {
        const reportDate = dayjs(report.created_at).format('DD/MM');
        if (reportDate in usesByDay) {
          usesByDay[reportDate] = report.uses;
        }
      });

      return Object.entries(usesByDay).map(([date, uses]) => uses);
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.totalizer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.totalizer .card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background: var(--box);
  border-radius: 10px;
  padding: 15px 25px;
}

.totalizer .card i {
  color: var(--default);
  font-size: 14px;
  margin-left: 5px;
}

.totalizer .card .value {
  color: #cccccc;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
}

.totalizer .card p:nth-child(2) {
  color: rgba(204, 204, 204, 0.4);
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--box-up);
}

@media screen and (min-width: 992px) {
  .benefit-infos {
    margin-left: 20px;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .totalizer {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 13px;
  }

  .totalizer .card {
    padding: 20px;
    align-items: center;
  }

  .totalizer .card .value {
    font-size: 16px;
    line-height: 19px;
  }

  .totalizer .card p:nth-child(2) {
    margin-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
