<template>
  <base-card>
    <base-label text="Defina a faixa etária" />
    <base-radio
      family="age-range"
      option="age-group"
      class="mt_15"
      @click="handleInputSelected('age-group')"
      :value="controllerInputs"
    >
      <p class="radio-label">Faixa de idade</p>
    </base-radio>
    <div class="d-flex mt_10" v-if="controllerInputs === 'age-group'">
      <base-input
        placeholder="De"
        class="w-50 mb_0"
        v-model="range.min"
        type="tel"
        @change="mountPayload('range')"
        @keyup="mountPayload('range')"
        :thousandSeparator="false"
        :moneyMask="StringLanguage.timeConfigs.years"
      />
      <base-input
        placeholder="Até"
        class="w-50 ml_15 mb_0"
        v-model="range.max"
        type="tel"
        @change="mountPayload('range')"
        @keyup="mountPayload('range')"
        :thousandSeparator="false"
        :moneyMask="StringLanguage.timeConfigs.years"
      />
    </div>

    <base-radio
      family="age-range"
      option="age-min-range"
      class="mt_15"
      type="tel"
      @click="handleInputSelected('age-min-range')"
      :value="controllerInputs"
    >
      <p class="radio-label">Idade mínima</p>
    </base-radio>
    <div class="d-flex mt_10" v-if="controllerInputs === 'age-min-range'">
      <base-input
        placeholder="A partir de"
        class="w-100 mb_0"
        v-model="minimum"
        type="tel"
        @change="mountPayload('minimum')"
        @keyup="mountPayload('minimum')"
        :thousandSeparator="false"
        :moneyMask="StringLanguage.timeConfigs.years"
      />
    </div>

    <base-radio
      family="age-range"
      option="age-exact"
      class="mt_15"
      @click="handleInputSelected('age-exact')"
      :value="controllerInputs"
    >
      <p class="radio-label">Idade exata</p>
    </base-radio>
    <div class="d-flex mt_10" v-if="controllerInputs === 'age-exact'">
      <base-input
        placeholder="Defina a idade"
        class="w-100 mb_0"
        v-model="exact"
        type="tel"
        @change="mountPayload('exact')"
        @keyup="mountPayload('exact')"
        :thousandSeparator="false"
        :moneyMask="StringLanguage.timeConfigs.years"
      />
    </div>
  </base-card>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseInput from '@/components/Input';
import BaseButton from '@/components/Button';
import BaseCheckbox from '@/components/Checkbox';
import BaseLabel from '@/components/Label';
import BaseRadio from '@/components/Radio';

const components = {
  BaseInput,
  BaseButton,
  BaseCheckbox,
  BaseLabel,
  BaseRadio,
  BaseCard,
};

export default {
  components,
  data() {
    return {
      range: {},
      exact: '',
      minimum: '',
      controllerInputs: 'age-group',
    };
  },
  methods: {
    handleInputSelected(value) {
      this.controllerInputs = value;
      this.exact = '';
      this.minimum = '';
      this.range = {};
      this.mountPayload('refresh');
    },
    mountPayload(type, value) {
      //If para bloquear a segunda chamada causada pelo v-bind do componente select
      if (value && value.target) {
        return false;
      }

      const payload = {
        type: 'clientAgeRange',
        value: {},
      };

      if (type === 'range') {
        payload.value = this.range;
      } else if (type === 'exact' || type === 'minimum') {
        payload.value = { [type]: this[type] };
      }

      this.$emit('save', payload);
    },
  },
  watch: {
    exact() {
      this.mountPayload('exact');
    },
    'range.max': function () {
      this.mountPayload('range');
    },
  },
};
</script>
