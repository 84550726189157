<template>
  <base-input
    ref="input"
    :label="!placeholder ? placeholder : ''"
    :placeholder="placeholder"
    v-model="addressString"
    :options="addressOptions"
    :optload="searching"
    @keyup="searchStringAddress"
    @optionSelected="onRegionSelected"
    :design="`${!!design ? `${design}` : ''}`"
    :search="search"
    :valid="valid"
    :records="records"
    :recordsText="recordsText"
  ></base-input>
</template>
<script>
import BaseInput from '@/components/Input';
import { PlaceList } from '@/utils/PlaceList';

export default {
  components: { BaseInput },
  props: {
    placeholder: { type: String, default: 'Buscar endereço e número' },
    search: Boolean,
    design: String,
    value: String,
    valid: { type: Boolean, default: true },
    records: { type: Number, default: 0 },
    countrySlug: { type: String, default: 'brasil' },
    stateSlug: { type: String, default: null },
    citySlug: { type: String, default: null },
    recordsText: { type: String, default: 'registros' },
  },
  data() {
    return {
      sessionToken: null,
      addressString: null,
      addressOptions: [],
      lastScheduledSearch: null,
      searching: false,
    };
  },
  mounted() {
    this.addressString = this.value;
  },
  methods: {
    searchStringAddress(e) {
      const { countrySlug, stateSlug, citySlug } = this.$props;

      console.log(countrySlug, stateSlug, citySlug);

      const addrString = (this.addressString || '').trim();
      this.addressOptions = [];

      if (this.lastScheduledSearch) {
        clearInterval(this.lastScheduledSearch);
        this.lastScheduledSearch = null;
      }

      if (addrString.length < 3) {
        this.searching = false;
        return;
      }

      this.searching = true;
      this.lastScheduledSearch = setTimeout(() => {

        if(countrySlug && stateSlug && citySlug) {
          const results = PlaceList.getNeighborhoods(countrySlug, stateSlug, citySlug, addrString);
          this.addressOptions = results.map(({ neighborhood, slug }) => ({ title: neighborhood, data: slug }))
        } else if(countrySlug && stateSlug) {
          const results =  PlaceList.getCities(countrySlug, stateSlug, addrString);
          this.addressOptions = results.map(({ city, slug }) => ({ title: city, data: slug }))
        } else if(countrySlug) {
          const results = PlaceList.getStates(countrySlug, addrString);
          this.addressOptions = results.map(({ state, slug }) => ({ title: state, data: slug }))
        } else {
          this.addressOptions = []
        }
        
        this.searching = false;
      }, 1000);
    },
    onRegionSelected(option, optionIndex, changeInput = true) {
      this.addressOptions = [];
      this.addressString = "";

      if (!option.data) {
        return;
      }

      this.$emit('selectedRegion', option);
    }
  }
};
</script>
