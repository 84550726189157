<template>
  <div>
    <div class="label-sb mb_20" v-if="!drawerMode">
      <base-label :text="title" />
      <p class="see-all" @click="$emit('seeAll')">Ver todos</p>
    </div>
    <base-label v-else :text="title" class="mb_20" />

    <div class="table-item title">
      <p v-for="(head, i) in headers" :key="i">{{ head }}</p>
    </div>
    <div class="table-item" v-for="(item, i) in paginate" :key="i">
      <p>
        <span v-if="!isMobile">{{ (page - 1) * itemsPerPage + i + 1 }}.</span> {{ item.name }}
      </p>
      <p>{{ item.value }}</p>
    </div>
  </div>
</template>

<script>
import BaseLabel from "@/components/Label";
import { mapState } from "vuex";

export default {
  props: {
    title: {},
    headers: {},
    items: {},
    itemsPerPage: {
      default: 5,
    },
    drawerMode: {
      default: false,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  components: {
    BaseLabel,
  },
  methods: {
    nextPage() {
      this.page += 1;
    },
    prevPage() {
      this.page -= 1;
    },
  },
  computed: {
    paginate() {
      if (this.drawerMode) return this.items;

      return this.items.filter(
        (item, index) =>
          index >= (this.page - 1) * this.itemsPerPage &&
          index < this.page * this.itemsPerPage
      );
    },
    ...mapState({
      isMobile: (state) => state.store.isMobile
    }),
  },
};
</script>

<style scoped>
.label-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #FFFFFF17;
}

.table-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4em;
  color: #AAAAAB;
  font-weight: 300;
  line-height: 15px;
}

.table-item span {
  font-weight: 900;
  color: #939495D9;
  margin-right: 10px;
}

.table-item.title {
  font-weight: 600;
  color: #CCCCCC;
  margin-bottom: 20px;
  border-bottom: none !important;
}

.table-item:not(:last-child) {
  border-bottom: 1px solid #FFFFFF17;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.table-item p:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-item p:nth-child(2) {
  margin-left: 20px;
}

.see-all {
  margin-bottom: 10px;
  font-size: 1.4em;
  color: #C9C9C9C7;
  cursor: pointer;
  /* text-transform: uppercase; */
}

.see-all:hover {
  color: var(--default);
}
</style>
