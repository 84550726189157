<template>
  <div class="details-benefit">
    <div class="benefit-code">
      <p>{{ benefit.code }} - {{ benefit.pin }}</p>
      <span>{{ getBenefitType(benefit.type) }}</span>
    </div>

    <div class="step">
      <p>Valor do benefício</p>
      <p>
        {{ getBenefitValue(benefit.type) }}
      </p>
    </div>
    
    <div class="step">
      <p>Pedido mínimo</p>
      <p>{{ Utils.convertMoney(benefit.min_order) }}</p>
    </div>

    <div class="step" v-if="benefit.expiration && benefit.expiration.gain">
      <p>Expiração do benefício</p>
      <p>{{ benefit.expiration.gain.days }} dias após</p>
    </div>
    
    <div class="step">
      <p>Tipo de uso</p>
      <p>{{ !!benefit.single_use ? 'Uníco' : 'Múltiplos' }}</p>
    </div>

    <div class="step">
      <p>Onde o benefício será utilizado</p>
      <p>{{ getUseType(benefit.use_type) }}</p>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';

const benefitTypes = {
  CASHBACK: 'CASHBACK',
  CASH_DISCOUNT: 'CASH_DISCOUNT',
  PERCENTAGE_DISCOUNT: 'PERCENTAGE_DISCOUNT',
  FREE_DELIVERY: 'FREE_DELIVERY',
};

const useTypes = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  BOTH: 'BOTH',
};

export default {
  components: {},
  props: {
    benefit: Object,
  },
  data() {
    return {
      Utils,
      benefitTypes,
    };
  },
  methods: {
    getBenefitValue(benefitType) {
      switch(benefitType) {
        case [benefitTypes.PERCENTAGE_DISCOUNT]:
          return `${this.benefit.value}%`
        case [benefitTypes.FREE_DELIVERY]:
            return "Entrega grátis"
        case [benefitTypes.CASH_DISCOUNT]:
        default:
          return Utils.convertMoney(this.benefit.value);
      }
    },
    getBenefitType(benefitType) {
      const types = {
        CASHBACK: 'Cashback',
        CASH_DISCOUNT: 'Desconto em R$',
        PERCENTAGE_DISCOUNT: 'Desconto em %',
        FREE_DELIVERY: 'Entrega grátis',
      };

      let type = null;
      if (benefitType in types) {
        type = types[benefitType];
      } else {
        type = benefitType;
      }

      return type;
    },
    getUseType(useType) {
      switch (useTypes[useType]) {
        case 'ONLINE': {
          return 'Delivery';
        }
        case 'OFFLINE': {
          return 'Consumo no local';
        }
        case 'BOTH': {
          return 'Consumo no local e delivery';
        }
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.details-benefit {
  border-radius: 10px;
  background: var(--box);
  padding: 20px;
  margin-bottom: 30px;
}

.details-benefit .benefit-code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px dashed #363e42;
  background: #272c30;
  border-radius: 10px;
  padding: 15px 40px;
  text-align: center;
}

.details-benefit .benefit-code p {
  color: rgba(170, 170, 171, 0.85);
  font-size: 14px;
  line-height: 17px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
}

.details-benefit .benefit-code span {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: rgba(190, 190, 191, 0.8);
}

.details-benefit .step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
}

.details-benefit .step:not(:last-child) {
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
}

.details-benefit .step:last-child {
  padding-bottom: 10px;
}

.details-benefit .step p:nth-child(1) {
  font-size: 14px;
  line-height: 17px;
  color: #aaaaab;
  margin-bottom: 15px;
}

.details-benefit .step p:nth-child(2) {
  color: rgba(190, 190, 191, 0.8);
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
}

@media screen and (min-width: 992px) {
  .details-benefit {
    width: 20%;
    min-width: 385px;
  }
}

@media screen and (max-width: 991px) {
  .details-benefit {
    width: 100%;
  }

  .details-benefit .step {
    padding: 15px 0;
  }

  .details-benefit .step p:nth-child(1) {
    margin-bottom: 10px;
  }
}
</style>
