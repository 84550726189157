<template>
  <div>
    <base-card>
      <base-label text="Defina o total gasto" />
      <base-radio
        family="average-spent"
        option="spent-range"
        class="mt_15"
        @click="handleInputSelected('spent-range')"
        :value="controllerInputs"
      >
        <p class="radio-label">Faixa de total gasto</p>
      </base-radio>
      <div class="d-flex mt_10" v-if="controllerInputs === 'spent-range'">
        <base-input
          placeholder="De"
          class="w-50 mb_0"
          v-model="range.min"
          type="tel"
          @change="mountPayload('range')"
          @keyup="mountPayload('range')"
          :money-mask="StringLanguage.currencyConfigs"
        />
        <base-input
          placeholder="Até"
          class="w-50 ml_15 mb_0"
          v-model="range.max"
          type="tel"
          @change="mountPayload('range')"
          @keyup="mountPayload('range')"
          :money-mask="StringLanguage.currencyConfigs"
        />
      </div>

      <base-radio
        family="average-spent"
        option="minimum-spent"
        class="mt_15"
        @click="handleInputSelected('minimum-spent')"
        :value="controllerInputs"
      >
        <p class="radio-label">Mínimo de total gasto</p>
      </base-radio>
      <div class="d-flex mt_10" v-if="controllerInputs === 'minimum-spent'">
        <base-input
          placeholder="A partir de"
          class="w-100 mb_0"
          v-model="minimum"
          type="tel"
          @change="mountPayload('minimum')"
          @keyup="mountPayload('minimum')"
          :money-mask="StringLanguage.currencyConfigs"
        />
      </div>

      <base-radio
        family="average-spent"
        option="exact-spent"
        class="mt_15"
        @click="handleInputSelected('exact-spent')"
        :value="controllerInputs"
      >
        <p class="radio-label">Total gasto exato</p>
      </base-radio>
      <div class="d-flex mt_10" v-if="controllerInputs === 'exact-spent'">
        <base-input
          @change="mountPayload('exact')"
          @keyup="mountPayload('exact')"
          v-model="exact"
          type="tel"
          placeholder="Defina o total de gastos"
          class="w-100 mb_0"
          :money-mask="StringLanguage.currencyConfigs"
        />
      </div>
    </base-card>

    <base-card>
      <base-label text="Determine o tempo" />
      <base-radio
        family="average-spent-time"
        option="all-spent"
        class="mt_15"
        @click="handleInputSelected('all-spent')"
        :value="controllerInputsDate"
      >
        <p class="radio-label">Todo o período</p>
      </base-radio>
      <base-radio
        family="average-spent-time"
        option="custom-spent"
        class="mt_15"
        @click="handleInputSelected('custom-spent')"
        :value="controllerInputsDate"
      >
        <p class="radio-label">Período personalizado</p>
      </base-radio>
    </base-card>

    <base-card v-if="controllerInputsDate === 'custom-spent'">
      <base-label text="Determine o período" />
      <div class="custom-period">
        <p>Nos últimos</p>

        <div class="d-flex">
          <base-input
            placeholder="Inserir valor"
            class="w-50 mb_0"
            v-model="inLast"
            type="tel"
            @change="(value) => mountPayload('inLast', value)"
            @keyup="(e) => mountPayload('inLast', e.target.value)"
          />
          <base-select
            title="Selecione o tempo..."
            class="w-50 ml_15"
            design="three"
            @change="(value) => mountPayload('inLastPeriod', value)"
          >
            <option :value="'DAY'" selected>Dias</option>
            <option :value="'MONTH'">Meses</option>
            <option :value="'YEAR'">Anos</option>
          </base-select>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseInput from '@/components/Input';
import BaseButton from '@/components/Button';
import BaseCheckbox from '@/components/Checkbox';
import BaseLabel from '@/components/Label';
import BaseRadio from '@/components/Radio';
import BaseSelect from '@/components/Select';

const components = {
  BaseInput,
  BaseButton,
  BaseCheckbox,
  BaseLabel,
  BaseRadio,
  BaseCard,
  BaseSelect,
};

export default {
  components,
  data() {
    return {
      exact: '',
      minimum: '',
      range: {},
      inLast: '',
      controllerInputs: 'spent-range',
      controllerInputsDate: 'all-spent',
      payload: {
        type: 'clientTotalSpent',
        value: {},
      },
    };
  },
  methods: {
    handleInputSelected(value) {
      if (value === 'custom-spent') {
        this.controllerInputsDate = 'custom-spent';
        this.mountPayload('inLast', NaN);
      } else if (value === 'all-spent') {
        this.controllerInputsDate = 'all-spent';
        this.inLast = '';
        delete this.payload.value.inLast;
        delete this.payload.value.inLastPeriod;
        this.mountPayload('refresh');
      } else if (value !== 'custom-spent') {
        this.controllerInputs = value;
        this.mountPayload(value.replace(/(-|spent)/g, ''));
      }
    },
    mountPayload(type, value) {
      //If para bloquear a segunda chamada causada pelo v-bind do componente select
      if (value && value.target) {
        return false;
      }

      const { payload } = this;
      if (type === 'range') {
        payload.value.min = this.range.min;
        payload.value.max = this.range.max;

        delete payload.value.exact;
        delete payload.value.minimum;
      } else if (type === 'exact') {
        payload.value.exact = this.exact;
        delete payload.value.min;
        delete payload.value.max;
        delete payload.value.minimum;
      } else if (type === 'minimum') {
        payload.value.minimum = this.minimum;
        delete payload.value.min;
        delete payload.value.max;
        delete payload.value.exact;
      }

      if (type == 'inLast') {
        payload.value.inLast = value;
      } else if (type == 'inLastPeriod') {
        payload.value.inLastPeriod = value;
      }

      this.$emit('save', payload);
    },
  }
};
</script>

<style scoped>
.custom-period {
  background: #2b3135;
  border-radius: 10px;
  padding: 15px 15px 5px;
  margin-top: 20px;
}
.custom-period p {
  color: var(--label-primary);
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
