<template>
  <div class="d-flex flex-column justify-between h-100">
    <div class="header">
      <p class="title">Segmentação</p>
    </div>
    <div class="h-100">
      <div class="d-flex flex-column justify-between h-100">
        <div>
          <div>
            <div
              class="collapse"
              v-for="(segmentGroup, groupIndex) of getSegmentationsGroup()"
              :key="groupIndex"
            >
              <div class="option" @click="toggleTab(segmentGroup.alias)">
                <i :class="segmentGroup.icon" />
                <p>{{ segmentGroup.name }}</p>
                <span
                  class="chevron"
                  :class="{ active: openedGroup == segmentGroup.alias }"
                >
                  <i class="far fa-chevron-down" />
                </span>
              </div>
              <div v-show="openedGroup == segmentGroup.alias" class="content">
                <div
                  v-for="(segment, segmentIndex) of segmentGroup.segments"
                  :key="segmentIndex"
                >
                  <base-checkbox
                    hover
                    class="mb_15"
                    :checked="isSegmentSelected(segment.type)"
                    @click="(e) => clickSegment(e, segment)"
                    :key="`${segment.type}-${Date.now()}`"
                  >
                    <p>{{ segment.name }}</p>
                  </base-checkbox>

                  <div class="tags" v-if="isSegmentSelected(segment.type)">
                    <div class="tag" v-if="hasTag(segment.type)">
                      {{ getTag(segment.type) }}
                    </div>
                    <div
                      class="tag"
                      v-for="(tag, tagIndex) of getMultipleTags(segment.type)"
                      :key="tagIndex"
                    >
                      {{ tag }}
                    </div>
                    <!--                     <div class="tag-geographic">
                      <span class="number">12</span>&nbsp;estado(s)
                    </div>
                    <div class="tag-geographic">
                      <span class="number">17</span>&nbsp;cidade(s)
                    </div>
                    <div class="tag-geographic">
                      <span class="number">44</span>&nbsp;bairro(s)
                    </div> -->
                    <div class="tag" v-if="hasPeriod(segment.type)">
                      {{ getPeriod(segment.type) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="d-flex mt_30 justify-end relative"
          :class="{ 'flex-column-reverse align-center': isMobile }"
        >
          <base-button
            text="Salvar e continuar"
            @click="$emit('save', audience)"
            class="big"
            :disabled="!canSave()"
            :class="{ 'w-100 mb_15': isMobile }"
          />
        </div>
      </div>
      <segment-drawer
        :drawer="drawer"
        :selectedSegment="selectedSegment"
        @save="(s) => addSegment(s)"
        @close="closeDrawer()"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Utils from '@/utils';
import BaseButton from '@/components/Button';
import BaseCheckbox from '@/components/Checkbox';

import SegmentDrawer from '@/containers/Publics/Drawers/SegmentDrawer.vue';

const SEGMENT_NAME = {
  behavioralDaysOff: 'Dias sem pedir',
  behavioralBirthday: 'Aniversariante',
  clientAgeRange: 'Faixa etária',
  clientRegisterTimer: 'Tempo de cadastro',
  clientAverageTicket: 'Ticket médio',
  clientOrderQuantity: 'Quantidade de conversões',
  clientTotalSpent: 'Total gasto',
  geographicDistrict: 'Região',
  regionRange: 'Região',
  weatherPhotography: 'Fotografia climática',
};

const components = {
  BaseButton,
  BaseCheckbox,
  SegmentDrawer,
};

export default {
  components,
  props: {
    loading: { type: Boolean, default: false },
    audience: { type: Object, default: () => ({ name: '' }) },
  },
  data() {
    return {
      stepPublic: 'public-options',
      saveAndCreatePublic: false,
      segmentationType: null,
      openedGroup: null,
      selectedSegment: null,
      drawer: { open: false, close: false },
      errorController: true,
      addSegmentations: {},
      tags: [],
      tagControllers: [],
      periodTag: '',
      selectedSegmentKey: `selected-segment-${Date.now()}`,
    };
  },
  methods: {
    clickSegment(event, segment) {
      if (event.target.checked) {
        event.preventDefault();
        if (segment.type != `behavioralBirthday`) {
          this.openDrawer(segment);
        } else {
          this.addSegment({ type: 'behavioralBirthday', value: null });
        }
      } else {
        this.removeSegment(segment);
      }
    },
    getSegmentByType(type) {
      const segmentations = Utils.nullishCoalescing(this, 'audience.rules', []);
      const segmentIndex = segmentations.map((s) => s.type).indexOf(type);

      if (segmentIndex < 0) {
        return null;
      }

      return segmentations[segmentIndex];
    },
    hasPeriod(type) {
      switch (type) {
        case 'behavioralBirthday':
        case 'clientAgeRange':
        case 'geographicDistrict':
        case 'regionRange':
        case 'weatherPhotography':
          return false;
        default:
          return true;
      }
    },
    hasTag(type) {
      switch (type) {
        case 'regionRange':
        case 'clientRegisterTimer':
        case 'weatherPhotography':
          return false;
        default:
          return true;
      }
    },
    getPeriod(type) {
      const segment = this.getSegmentByType(type);
      if (!segment) {
        return null;
      }

      const TIME_ENUM = {
        DAY: { singular: 'dia', plural: 'dias' },
        MONTH: { singular: 'mês', plural: 'meses' },
        YEAR: { singular: 'ano', plural: 'anos' },
      };

      const { inLast, inLastPeriod } = segment.value;
      if (!inLast) {
        return `Todo período`;
      }
      if (inLast === 1) {
        return `No ultimo ${TIME_ENUM[inLastPeriod].singular}`;
      }
      return `Nos ultimos ${inLast} ${TIME_ENUM[inLastPeriod].plural}`;
    },
    getTag(type) {
      const segment = this.getSegmentByType(type);
      if (!segment) {
        return null;
      }

      if (segment.type === 'regionRange') {
        return null;
      }

      if (segment.type === 'geographicDistrict') {
        const address = segment.value;
        return `${address.district}`;
      }

      if (segment.type == 'behavioralBirthday') {
        return `Aniversariante`;
      }

      const configs = segment.value;
      if (configs.min && configs.max) {
        return `${SEGMENT_NAME[segment.type]} (${configs.min} a ${
          configs.max
        })`;
      }
      if (configs.minimum) {
        return `${SEGMENT_NAME[segment.type]} (+${configs.minimum})`;
      }
      if (configs.exact) {
        return `${SEGMENT_NAME[segment.type]} (${configs.exact})`;
      }
    },
    getMultipleTags(type) {
      const segment = this.getSegmentByType(type);
      if (!segment) return [];
      if (!['weatherPhotography', 'regionRange'].includes(segment.type))      
       return [];

      const configs = segment.value;
      if (!configs) return [];
      
      
      let tags = [];
      if(segment.type == 'regionRange') {
        tags.push(...configs.map((s) => {
          if(s.cities.length == 0) {
              return s.state;
          } else if(s.cities.length == 1) {
            const neighborhoods = s.cities[0].neighborhoods.length > 0 ? ` (${s.cities[0].neighborhoods.length})` : '';
            return `${s.cities[0].city} - ${s.state}${neighborhoods}`;
          } else {
            return `${s.state} (${s.cities.length})`;
          }
        }));
        return tags;
      }      

      if (configs.temperature)
        tags.push(
          `Temperatura (${configs.temperature.min} - ${configs.temperature.max})`
        );

      if (configs.feelslike)
        tags.push(
          `Sensação (${configs.feelslike.min} - ${configs.feelslike.max})`
        );

      if (configs.moisture)
        tags.push(
          `Umidade (${configs.moisture.min} - ${configs.moisture.max})`
        );

      if (configs.weather) {
        const featureTags = configs.weather.map((feature) => {
          if (feature === 'CLEAR') return 'Ensolarado';
          if (feature === 'RAIN') return 'Chovendo';
          if (feature === 'CLOUDY') return 'Nublado';
          if (feature === 'PARTLY_CLOUDY') return 'Parcialmente nublado';
          return '';
        });
        tags = tags.concat(featureTags);
      }

      if (configs.quantity) tags.push(`${configs.quantity.exact} Pedidos`);

      return tags;
    },
    toggleTab(alias) {
      this.openedGroup = this.openedGroup === alias ? null : alias;
    },
    openDrawer(segment) {
      this.errorController = true;
      this.selectedSegment = segment;
      this.drawer.open = !this.drawer.open;
    },
    closeDrawer() {
      this.selectedSegment = null;
      this.resetSegmentKey();
      this.drawer.close = !this.drawer.close;
    },
    resetSegmentKey() {
      // Método para limpar o cache do componente de segmentação durante o seu salvamento e fechamento
      this.selectedSegmentKey = `selected-segment-${Date.now()}`;
    },
    isSegmentSelected(type) {
      const segmentations = Utils.nullishCoalescing(this, 'audience.rules', []);
      if (segmentations.length < 0) {
        return false;
      }

      return segmentations.filter((segment) => segment.type == type).length > 0;
    },
    getSegmentationsGroup() {
      const groups = [];

      groups.push({
        name: 'Comportamental',
        alias: 'behavioral',
        icon: 'fal fa-mobile-alt',
        segments: [
          { type: 'behavioralDaysOff', name: 'Dias sem pedir' },
          { type: 'behavioralBirthday', name: 'Aniversariante' },
        ],
      });

      groups.push({
        name: 'Cliente',
        alias: 'customer',
        icon: 'fal fa-user',
        segments: [
          { type: 'clientAgeRange', name: 'Faixa etária' },
          { type: 'clientRegisterTimer', name: 'Tempo de cadastro' },
          { type: 'clientAverageTicket', name: 'Ticket médio' },
          { type: 'clientOrderQuantity', name: 'Quantidade de conversões' },
          { type: 'clientTotalSpent', name: 'Total gasto' },
        ],
      });

      groups.push({
        name: 'Geográfica',
        alias: 'geographic',
        icon: 'fal fa-map-marked',
        segments: [{ type: 'regionRange', name: 'Região' }],
      });

      groups.push({
        name: 'Climática',
        alias: 'climate',
        icon: 'fal fa-cloud-sun',
        segments: [{ type: 'weatherPhotography', name: 'Fotografia climática' }],
      });

      return groups;
    },
    addSegment(segment) {
      this.audience.rules = Utils.nullishCoalescing(
        this,
        'audience.rules',
        []
      ).filter((s) => s.type != segment.type);
      this.audience.rules.push(segment);
      this.closeDrawer();
    },
    removeSegment(segment) {
      const { type } = segment;
      const segmentations = Utils.nullishCoalescing(this, 'audience.rules', []);
      this.audience.rules = segmentations.filter((item) => item.type != type);
    },
    canSave() {
      return this.audience.name && this.audience.rules.length > 0;
    },
    save() {
      this.$emit('save', this.audience);
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

.header .title {
  color: #aaaaab;
  line-height: 19px;
  font-size: 16px;
  font-weight: bold;
}

.collapse .option {
  background: rgba(46, 52, 56, 0.65);
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.collapse .option:hover {
  background: #2e3438bd;
}

.collapse .option .chevron {
  position: absolute;
  right: 10px;
  color: var(--icon-inactive);
}

.collapse .option .chevron i {
  transition: all 0.2s;
  font-size: 11px;
}

.collapse .option .chevron.active i {
  transform: rotate(180deg);
}

.collapse .option i {
  font-size: 15px;
  margin-right: 10px;
  color: var(--icon-inactive);
}

.collapse .option p {
  color: var(--label-primary);
  font-size: 12px;
  line-height: 15px;
}

.collapse .content {
  padding: 10px 0 5px 15px;
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.tags .tag {
  color: var(--label-secondary);
  font-weight: bold;
  padding: 5px 10px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 7px;
  border: 1px solid #ffffff17;
  margin-bottom: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tags .tag-geographic {
  background: #2b3033;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: rgba(204, 204, 204, 0.7);
}

.tags .tag-geographic .number {
  font-weight: 800;
  color: #cccccc;
}

.coupons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.segmentation-type {
  padding-bottom: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  color: rgba(190, 190, 191, 0.75);
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
}

.segmentation-type i {
  color: rgba(190, 190, 191, 0.75);
  font-size: 12px;
  margin-right: 15px;
}

.grid-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.publics {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.publics .public {
  background: rgba(46, 52, 56, 0.65);
  padding: 5px;
  border-radius: 10px;
}

.publics .public .content {
  background: rgba(30, 33, 36, 0.3);
  padding: 0 15px 5px;
  border-radius: 10px;
  height: 100%;
}

.publics .public .content .header-public {
  display: flex;
  align-items: baseline;
  padding-bottom: 20px;
  padding-top: 15px;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  position: relative;
}

.publics .public .content .header-public .name {
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: #cccccc;
  font-weight: bold;
  margin-left: 15px;
}

.publics .public .content .header-public i {
  color: var(--icon-inactive);
  font-size: 15px;
}

.publics .public .content .tags-public {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}

.publics .public .content .tags-public .tag {
  background: #252a2e;
  color: rgba(170, 170, 171, 0.7);
  font-weight: bold;
  padding: 5px 15px;
  font-size: 12px;
  line-height: 15px;
  border-radius: 10px;
  border: 1px solid rgba(111, 118, 126, 0.2);
  margin-bottom: 10px;
  margin-right: 10px;
}

@media screen and (min-width: 992px) {
  .absolute-checkbox {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 991px) {
  .grid-options {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px 20px;
  }
}
</style>
