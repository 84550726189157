<template>
  <div class="h-100 d-flex flex-column">
    <div class="header">
      <p class="title">Nome do público</p>
    </div>
    <div class="d-flex flex-column justify-between h-100">
      <div style="flex: 1">
        <base-input v-model="audienceName" />
      </div>
      <div class="button-bottom">
        <base-button
          text="Continuar"
          :disabled="!canSave()"
          @click="onSave()"
          class="big"
        />
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from '@/components/Input';
import BaseButton from '@/components/Button';

const components = {
  BaseInput,
  BaseButton,
};

export default {
  components,
  props: {
    audience: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.audienceName = this.$props.audience.name;
  },
  data: () => ({ audienceName: '' }),
  methods: {
    canSave() {
      return this.audienceName && this.audienceName.length > 3;
    },
    onSave() {
        this.$emit('save', this.audienceName)
    }
  },
};
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

.header .title {
  color: #aaaaab;
  line-height: 19px;
  font-size: 16px;
  font-weight: bold;
}

.button-bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  flex: 1;
  align-items: flex-end;
}
</style>