<template>
  <base-drawer
    :open="drawer.open"
    :close="drawer.close"
    :title="selectedSegment && selectedSegment.name"
    @onClose="closeDrawer()"
    :goBack="
      backEnabled &&
      selectedSegment &&
      selectedSegment.type == 'regionRange'
    "
    :back="
      backEnabled &&
      selectedSegment &&
      selectedSegment.type == 'regionRange'
    "
    @onBack="prevStep()"
  >
    <div v-if="selectedSegment" :key="selectedSegmentKey">
      <div v-if="selectedSegment.type == 'clientAgeRange'">
        <client-age-range @save="handleError" @click="errorController = true" />
      </div>
      <div v-if="selectedSegment.type == 'behavioralDaysOff'">
        <behavioral-days-off
          @save="handleError"
          @click="errorController = true"
        />
      </div>
      <div v-if="selectedSegment.type == 'clientRegisterTimer'">
        <client-register-timer
          @save="handleError"
          @click="errorController = true"
        />
      </div>
      <div v-if="selectedSegment.type == 'clientAverageTicket'">
        <client-average-ticket
          @save="handleError"
          @click="errorController = true"
        />
      </div>
      <div v-if="selectedSegment.type == 'clientOrderQuantity'">
        <client-order-quantity
          @save="handleError"
          @click="errorController = true"
        />
      </div>
      <div v-if="selectedSegment.type == 'clientTotalSpent'">
        <client-total-spent
          @save="handleError"
          @click="errorController = true"
        />
      </div>
      <div v-if="selectedSegment.type == 'geographicDistrict'">
        <geographic-district
          @save="handleError"
          @click="errorController = false"
          ref="geographic-district"
          @currentStep="currentStep"
        />
      </div>
      <div v-if="selectedSegment.type == 'regionRange'">
        <region-range
          @save="handleError"
          @click="errorController = false"
          ref="region-range"
          @currentStep="currentStep"
        />
      </div>
      <div v-if="selectedSegment.type == 'weatherPhotography'">
        <weather-photography
          @save="handleError"
          @click="errorController = false"
          ref="weather-photography"
          @currentStep="currentStep"
        />
      </div>
    </div>
    <template slot="footer">
      <base-button
        text="Salvar"
        class="w-100"
        @click="save(addSegmentations)"
        :disabled="errorController"
      />
    </template>
  </base-drawer>
</template>

<script>
import Utils from '@/utils';
import BaseButton from '@/components/Button';
import BaseDrawer from '@/components/Drawer';

import ClientRegisterTimer from '@/containers/Publics/Drawers/SegmentationDrawers/client/clientRegisterTimer.vue';
import ClientAgeRange from '@/containers/Publics/Drawers/SegmentationDrawers/client/clientAgeRange.vue';
import ClientAverageTicket from '@/containers/Publics/Drawers/SegmentationDrawers/client/clientAverageTicket.vue';
import ClientOrderQuantity from '@/containers/Publics/Drawers/SegmentationDrawers/client/clientOrderQuantity.vue';
import ClientTotalSpent from '@/containers/Publics/Drawers/SegmentationDrawers/client/clientTotalSpent.vue';
import BehavioralDaysOff from '@/containers/Publics/Drawers/SegmentationDrawers/behavioral/behavioralDaysOff.vue';
import GeographicDistrict from '@/containers/Publics/Drawers/SegmentationDrawers/geographic/geographicDistrict.vue';
import RegionRange from '@/containers/Publics/Drawers/SegmentationDrawers/geographic/regionRange.vue';

import WeatherPhotography from '@/containers/Publics/Drawers/SegmentationDrawers/weatherphotography/WeatherPhotography.vue';

const components = {
  BaseButton,
  BaseDrawer,
  ClientRegisterTimer,
  ClientAgeRange,
  ClientAverageTicket,
  ClientOrderQuantity,
  ClientTotalSpent,
  BehavioralDaysOff,
  GeographicDistrict,
  WeatherPhotography,
  RegionRange
};

export default {
  components,
  props: {
    selectedSegment: {
      type: Object,
      default: null,
    },
    drawer: {
      type: Object,
      default: () => ({
        open: false,
        close: false,
      }),
    },
  },
  data() {
    return {
      segmentationType: null,
      errorController: true,
      addSegmentations: {},
      selectedSegmentKey: `selected-segment-${Date.now()}`,
      backEnabled: true,
    };
  },
  methods: {
    prevStep() {
      this.$refs['region-range'].prevStep();
    },
    currentStep(step) {
      this.backEnabled = step !== 1;
    },
    closeDrawer() {
      this.errorController = true;
      this.addSegmentations = {};
      this.$emit('close');
      this.resetSegmentKey();
    },
    resetSegmentKey() {
      // Método para limpar o cache do componente de segmentação durante o seu salvamento e fechamento
      this.selectedSegmentKey = `selected-segment-${Date.now()}`;
    },
    validateClimate({
      temperature,
      feelslike,
      moisture,
      weather,
      rain,
      quantity,
    }) {
      if (temperature && Number(temperature.max) <= Number(temperature.min))
        return false;
      if (feelslike && Number(feelslike.max) <= Number(feelslike.min))
        return false;
      if (moisture && Number(moisture.max) <= Number(moisture.min))
        return false;
      if (weather) {
        if (
          weather.includes('RAIN') &&
          rain &&
          Number(rain.max) <= Number(rain.min)
        )
          return false;
        if (!weather.includes('RAIN') && weather.length <= 0) return false;
      }

      if (quantity && quantity.exact <= 0) return false;

      if (!temperature && !feelslike && !moisture && !weather && !rain)
        return false;
      return true;
    },
    handleError(configs) {
      const { selectedSegment } = this;
      const {
        minimum,
        exact,
        max,
        min,
        district,
        inLast,
        inLastPeriod,
        temperature,
        feelslike,
        moisture,
        weather,
        rain,
        quantity,
      } = Utils.nullishCoalescing(configs, 'value', {});

      let isValidDatas = false;
      switch (selectedSegment.type) {
        case 'regionRange':
          this.addSegmentations = configs;
          this.errorController = configs.value.length == 0;
          return;
        case 'clientRegisterTimer':
          if (
            Number(inLast) > 0 &&
            ['DAY', 'MONTH', 'YEAR'].includes(inLastPeriod)
          ) {
            this.addSegmentations = configs;
            this.errorController = false;
            return;
          }
          break;
        case 'weatherPhotography':
          isValidDatas = this.validateClimate({
            temperature,
            feelslike,
            moisture,
            weather,
            rain,
            quantity,
          });

          if (isValidDatas) {
            this.addSegmentations = configs;
            this.errorController = false;
            return;
          }
          break;
        default:
          // Se periodos estiverem definidos, validar periodos, senão define como válido
          const isValidDates =
            inLast === undefined
              ? true
              : Number(inLast) > 0 &&
                ['DAY', 'MONTH', 'YEAR'].includes(inLastPeriod);
          // Valida os campos
          isValidDatas =
            minimum > 0 ||
            exact > 0 ||
            Number(max) > Number(min) ||
            district !== undefined;
          if (isValidDates && isValidDatas) {
            this.addSegmentations = configs;
            this.errorController = false;
            return;
          }
      }

      this.errorController = true;
    },
    save(segment) {
      this.$emit('save', segment);
    },
  },
};
</script>

<style scoped>
.collapse .option {
  background: rgba(46, 52, 56, 0.65);
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.collapse .option:hover {
  background: #2e3438bd;
}

.collapse .option .chevron {
  position: absolute;
  right: 10px;
  color: var(--icon-inactive);
}

.collapse .option .chevron i {
  transition: all 0.2s;
  font-size: 11px;
}

.collapse .option .chevron.active i {
  transform: rotate(180deg);
}

.collapse .option i {
  font-size: 15px;
  margin-right: 10px;
  color: var(--icon-inactive);
}

.collapse .option p {
  color: var(--label-primary);
  font-size: 12px;
  line-height: 15px;
}

.collapse .content {
  padding: 10px 0 5px 0;
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.tags .tag {
  color: var(--label-secondary);
  font-weight: bold;
  padding: 5px 10px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 7px;
  border: 1px solid #ffffff17;
  margin-bottom: 5px;
  margin-right: 5px;
}

.coupons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.segmentation-type {
  padding-bottom: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  color: rgba(190, 190, 191, 0.75);
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
}

.segmentation-type i {
  color: rgba(190, 190, 191, 0.75);
  font-size: 12px;
  margin-right: 15px;
}

.grid-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.publics {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.publics .public {
  background: rgba(46, 52, 56, 0.65);
  padding: 5px;
  border-radius: 10px;
}

.publics .public .content {
  background: rgba(30, 33, 36, 0.3);
  padding: 0 15px 5px;
  border-radius: 10px;
  height: 100%;
}

.publics .public .content .header-public {
  display: flex;
  align-items: baseline;
  padding-bottom: 20px;
  padding-top: 15px;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  position: relative;
}

.publics .public .content .header-public .name {
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: #cccccc;
  font-weight: bold;
  margin-left: 15px;
}

.publics .public .content .header-public i {
  color: var(--icon-inactive);
  font-size: 15px;
}

.publics .public .content .tags-public {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}

.publics .public .content .tags-public .tag {
  background: #252a2e;
  color: rgba(170, 170, 171, 0.7);
  font-weight: bold;
  padding: 5px 15px;
  font-size: 12px;
  line-height: 15px;
  border-radius: 10px;
  border: 1px solid rgba(111, 118, 126, 0.2);
  margin-bottom: 10px;
  margin-right: 10px;
}

@media screen and (min-width: 992px) {
  .absolute-checkbox {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 991px) {
  .grid-options {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px 20px;
  }
}
</style>
