<template>
  <div>
    <base-card>
      <base-label text="Determine o período" />
      <div class="custom-period">
        <p>Nos últimos</p>
        <div class="d-flex">
          <base-input
            placeholder="Inserir valor"
            class="w-50 mb_0"
            v-model="inLast"
            type="tel"
            @change="(value) => mountPayload('inLast', value)"
            @keyup="(e) => mountPayload('inLast', e.target.value)"
          />
          <base-select
            title="Selecione o tempo..."
            class="w-50 ml_15"
            design="three"
            v-model="inLastPeriod"
            @change="(value) => mountPayload('inLastPeriod', value)"
          >
            <option :value="'DAY'" selected>Dias</option>
            <option :value="'MONTH'">Meses</option>
            <option :value="'YEAR'">Anos</option>
          </base-select>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseInput from '@/components/Input';
import BaseButton from '@/components/Button';
import BaseLabel from '@/components/Label';
import BaseSelect from '@/components/Select';

const components = {
  BaseInput,
  BaseButton,
  BaseLabel,
  BaseCard,
  BaseSelect,
};

export default {
  components,
  data() {
    return {
      inLast: '',
      inLastPeriod: '',
      payload: {
        type: 'clientRegisterTimer',
        value: {},
      },
    };
  },
  methods: {
    mountPayload(type, value) {
      const { payload } = this;

      //If para bloquear a segunda chamada causada pelo v-bind do componente select
      if (value && value.target) {
        return false;
      }

      if (type === 'inLast') {
        payload.value.inLast = value;
      } else if (type === 'inLastPeriod') {
        payload.value.inLastPeriod = value;
      }

      this.$emit('save', payload);
    },
  },
};
</script>

<style scoped>
.custom-period {
  background: #2b3135;
  border-radius: 10px;
  padding: 15px 15px 5px;
  margin-top: 20px;
}

.custom-period p {
  color: var(--label-primary);
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
