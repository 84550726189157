<template>
  <div>
    <base-card>
      <div v-for="item in climate" :key="item.id" class="climate-variant">
        <div
          class="header"
          @click="
            item.open = !item.open;
            payload[item.model].selected = true;
          "
          :class="{ opened: item.open }"
        >
          <div class="d-flex">
            <base-checkbox
              v-model="payload[item.model].selected"
              :checked="payload[item.model].selected"
            />
            <p>{{ item.name }}</p>
          </div>
          <span class="open">
            <i class="fal fa-chevron-up" v-if="item.open" />
            <i class="fal fa-chevron-down" v-else />
          </span>
        </div>

        <div class="content" v-if="item.open">
          <div v-if="item.id === 0">
            <base-label text="Você deseja usar:" />
            <div class="grid-options">
              <div
                class="option"
                :class="{
                  active:
                    payload.temperature.temperatureType ===
                      climateTypes.TEMPERATURE && payload.temperature.selected,
                }"
                @click="
                  payload.temperature.temperatureType =
                    climateTypes.TEMPERATURE;
                  payload.temperature.selected = true;
                "
              >
                <i
                  class="fal fa-temperature-half"
                  v-if="
                    payload.temperature.temperatureType !==
                      climateTypes.TEMPERATURE || !payload.temperature.selected
                  "
                />
                <i
                  class="fal fa-check"
                  v-if="
                    payload.temperature.temperatureType ===
                      climateTypes.TEMPERATURE && payload.temperature.selected
                  "
                />
                <p>Temperatura</p>
              </div>

              <div
                class="option"
                :class="{
                  active:
                    payload.temperature.temperatureType ===
                      climateTypes.THERMAL_SENSATION &&
                    payload.temperature.selected,
                }"
                @click="
                  payload.temperature.temperatureType =
                    climateTypes.THERMAL_SENSATION;
                  payload.temperature.selected = true;
                "
              >
                <i
                  class="fal fa-heat"
                  v-if="
                    payload.temperature.temperatureType !==
                      climateTypes.THERMAL_SENSATION ||
                    !payload.temperature.selected
                  "
                />
                <i
                  class="fal fa-check"
                  v-if="
                    payload.temperature.temperatureType ===
                      climateTypes.THERMAL_SENSATION &&
                    payload.temperature.selected
                  "
                />
                <p>Sensação térmica</p>
              </div>
            </div>

            <div class="line"></div>

            <base-label text="Defina a faixa" />
            <div class="d-flex">
              <base-input
                class="w-100 mr_15"
                label="Faixa mín"
                :money-mask="StringLanguage.temperatureConfigs.celsius"
                v-model="payload.temperature.min"
              />
              <base-input
                class="w-100"
                label="Faixa máx"
                :money-mask="StringLanguage.temperatureConfigs.celsius"
                v-model="payload.temperature.max"
              />
            </div>

            <div class="line-dashed"></div>
          </div>

          <div v-if="item.id === 1">
            <base-label text="Defina a faixa" />
            <div class="d-flex">
              <base-input
                class="w-100 mr_15"
                label="Faixa mín"
                :money-mask="StringLanguage.percentIntegerConfigs"
                v-model="payload.moisture.min"
              />
              <base-input
                class="w-100"
                label="Faixa máx"
                :money-mask="StringLanguage.percentIntegerConfigs"
                v-model="payload.moisture.max"
              />
            </div>

            <div class="line-dashed"></div>
          </div>

          <div v-if="item.id === 2">
            <base-label text="Defina o tipo" />
            <base-checkbox
              @click="featureSelector(climateFeatureValues.CLEAR)"
              class="mb_15"
              >Ensolarado</base-checkbox
            >
            <base-checkbox
              @click="featureSelector(climateFeatureValues.PARTLY_CLOUDY)"
              class="mb_15"
              >Parcialmente nublado</base-checkbox
            >
            <base-checkbox
              @click="featureSelector(climateFeatureValues.CLOUDY)"
              class="mb_15"
              >Nublado</base-checkbox
            >
            <base-checkbox
              @click="
                featureSelector(climateFeatureValues.RAIN);
                payload.rain.selected = !payload.rain.selected;
              "
              class="mb_15"
              >Chovendo</base-checkbox
            >

            <base-label text="Defina a quantidade de chuva" class="mt_20" />
            <div class="d-flex">
              <base-input
                class="w-100 mr_15"
                label="Faixa mín"
                :money-mask="StringLanguage.precipitationConfigs.millimeters"
                v-model="payload.rain.min"
              />
              <base-input
                class="w-100"
                label="Faixa máx"
                :money-mask="StringLanguage.precipitationConfigs.millimeters"
                v-model="payload.rain.max"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="line-dashed"></div>

      <base-label text="Quantidade de pedidos neste padrão" />
      <base-input
        class="w-100"
        type="tel"
        :thousandSeparator="false"
        v-model="payload.quantity.exact"
        :moneyMask="StringLanguage.general.conversions"
      />
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseInput from '@/components/Input';
import BaseButton from '@/components/Button';
import BaseCheckbox from '@/components/Checkbox';
import BaseLabel from '@/components/Label';
import BaseRadio from '@/components/Radio';
import BaseSelect from '@/components/Select';

const components = {
  BaseInput,
  BaseButton,
  BaseCheckbox,
  BaseLabel,
  BaseRadio,
  BaseCard,
  BaseSelect,
};

const climateTypes = {
  TEMPERATURE: 'temperature',
  THERMAL_SENSATION: 'thermalSensation',
};

const climateFeatureValues = {
  CLEAR: 'clear',
  RAIN: 'rain',
  CLOUDY: 'cloudy',
  PARTLY_CLOUDY: 'partly_cloudy',
};

export default {
  components,
  data() {
    return {
      payload: {
        temperature: {
          selected: false,
          temperatureType: null,
          min: null,
          max: null,
        },
        moisture: {
          selected: false,
          min: null,
          max: null,
        },
        weather: {
          selected: false,
          values: [],
        },
        rain: {
          selected: false,
          min: null,
          max: null,
        },
        quantity: {
          exact: 0,
        },
      },
      climateTypes,
      climateFeatureValues,
      climate: [
        {
          id: 0,
          name: 'Temperatura',
          model: 'temperature',
          open: false,
        },
        {
          id: 1,
          name: 'Umidade',
          model: 'moisture',
          open: false,
        },
        {
          id: 2,
          name: 'Características',
          model: 'weather',
          open: false,
        },
      ],
    };
  },
  methods: {
    mountPayload(type, value) {
      // If para bloquear a segunda chamada causada pelo v-bind do componente select
      if (value && value.target) {
        return false;
      }

      const payload = {
        type: 'weatherPhotography',
        value: {},
      };

      if (this.payload.rain.min !== this.payload.rain.max)
        this.payload.rain.selected = true;

      const keys = Object.keys(this.payload);

      const selectedConfigs = keys.filter(
        (key) => this.payload[key].selected || key === 'quantity'
      );
      for (let i = 0; i < selectedConfigs.length; i++) {
        const { min, max, exact, values, temperatureType } =
          this.payload[selectedConfigs[i]];
        if (temperatureType === climateTypes.THERMAL_SENSATION) {
          payload.value.feelslike = { min, max };
        } else {
          if (min)
            payload.value[selectedConfigs[i]] = {
              ...payload.value[selectedConfigs[i]],
              min,
            };
          if (max)
            payload.value[selectedConfigs[i]] = {
              ...payload.value[selectedConfigs[i]],
              max,
            };
          if (exact || exact === 0)
            payload.value[selectedConfigs[i]] = {
              ...payload.value[selectedConfigs[i]],
              exact,
            };
          if (values) payload.value[selectedConfigs[i]] = values;
        }
      }

      this.$emit('save', payload);
    },
    async featureSelector(data) {
      const featureValue = Object.keys(climateFeatureValues).find(
        (featureName) => climateFeatureValues[featureName] === data
      );
      if (!this.payload.weather.values.includes(featureValue)) {
        this.payload.weather.values.push(featureValue);
      } else {
        this.payload.weather.values.splice(
          this.payload.weather.values.indexOf(featureValue),
          1
        );
      }
    },
  },
  watch: {
    payload: {
      handler(newValue) {
        this.mountPayload(newValue);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.climate-variant {
  margin-bottom: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 20px;
  background: #222629;
  border-radius: 10px;
}

.header p {
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: rgba(170, 170, 171, 0.95);
  margin-left: 5px;
}

.header.opened {
  margin-bottom: 20px;
}

.line {
  margin: 30px 0;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}

.line-dashed {
  margin-top: 15px;
  margin-bottom: 30px;
  border-bottom: 2px dashed #ffffff17;
}

.grid-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.grid-options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box-up);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.grid-options .option i {
  color: #6f767e;
  font-size: 25px;
  margin-bottom: 15px;
}

.grid-options .option p {
  color: #aaaaab;
  font-size: 12px;
  line-height: 15px;
}

.grid-options .option.active :is(p, i) {
  color: var(--default);
}
</style>
